<script setup lang="ts">
import type { IColumn, ITenantSchedule } from '@audit'

import type { IPagination } from '@shared/composables/usePagination'
import { useScheduleColumns } from '@audit/components/composables/useScheduleColumns'
import { useModal } from 'vue-final-modal'
import useColumnLoading from './composables/useColumnLoading'

interface Props {
  data: ITenantSchedule[]
  columns: IColumn[] | undefined
  loading?: boolean
  pagination?: IPagination
  total?: number
  selectedRows?: ITenantSchedule[]
  searchQuery: string
}
const props = withDefaults(defineProps<Props>(), {
  loading: false,
  pagination: () => ({ page: 1, pageSize: 25 }),
  total: 0,
  selectedRows: () => [],
})

const selectedSchedule = ref<ITenantSchedule>()

const { close: closeEditModal } = useModal({
  component: getAsyncComponent('TenancyScheduleFormModal'),
  attrs: {
    onClose: () => {
      closeEditModal()
      selectedSchedule.value = undefined
    },
    mode: 'update',
    schedule: selectedSchedule.value,
  },
})

const filteredData = computed(() => {
  if (!props.searchQuery) return props.data

  return props.data.filter((schedule) =>
    (schedule.tenantName as string)
      ?.toLowerCase()
      .includes(props.searchQuery?.toLowerCase() || ''),
  )
})

const colsSkeleton = useColumnLoading()
const formattedColumns = useScheduleColumns(
  computed(() => props.columns ?? colsSkeleton),
)
</script>

<template>
  <DataTable
    :total-records="total"
    :columns="formattedColumns"
    :data="filteredData"
    :loading="loading"
  >
    <template #empty>
      <slot name="empty" />
    </template>
  </DataTable>
</template>
